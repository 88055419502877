export const ADDRESSES = {
    treasury: "0x1f1b2c8FF594E7f325594232d510234573675BbC",
    matic: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
    avax: "0x1CE0c2827e2eF14D5C4f29a091d735A204794041",
    ftm: "0xAD29AbB318791D579433D831ed122aFeAf29dcfe",
    busd: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    link: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
    ada: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    atom: "0x0Eb3a705fc54725037CC9e008bDede697f62F335",
    sol: "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
    sand: "0x67b725d7e342d7B611fa85e859Df9697D9378B2e",
    mbox: "0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377",
    kxa: "0x2223bF1D7c19EF7C06DAB88938EC7B85952cCd89",
    kxaTreasury: "0xd7b3398F528975CB1b966254ad16DA5E52217e7d",
    ibat: "0x19cd9B8e42d4EF62c3EA124110D5Cfd283CEaC43",
    wbnb: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    bearLP: "0x60783c1b91795adfd3add1a9492e37aec8a6e810",
    bear: "0xd1421f138Fd1bCa936C1c4b2cCc80Fc133372e77",
    bearUnicrypt: "0xeaed594b5926a7d5fbbc61985390baaf936a6b8d",
    bearStaking: "0xfbec91253b539de082e2da751c784228c2384842",
    deadBear: "0x000000000000000000000000000000000000dead",
    deployerBear: "0xd1421f138fd1bca936c1c4b2ccc80fc133372e77",
    marketingBear: "0xd7b3398F528975CB1b966254ad16DA5E52217e7d",
    devBear: "0xc414f2d604eb7B6c5C1dA41f80Ca0d7C6fA03B6a"
};
