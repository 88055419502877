//import dependencies
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ADDRESSES } from "../utils/address/addresses";
import { BEAR_ABI, TREASURY_ABI } from "../utils/providersAndABI/ABIs";
import { provider } from "../utils/providersAndABI/provider";
// logos
import { bnbLogo, wood } from "../Assets";
import ArbitrageCalculation from "./ArbitrageCalculation";
import GamingUnity from "./GamingUnity";
import TopTable from "./TopTable";

// //assigning contract values to each token
const matic = new ethers.Contract(ADDRESSES.matic, TREASURY_ABI, provider);
const avax = new ethers.Contract(ADDRESSES.avax, TREASURY_ABI, provider);
const ftm = new ethers.Contract(ADDRESSES.ftm, TREASURY_ABI, provider);
const busd = new ethers.Contract(ADDRESSES.busd, TREASURY_ABI, provider);
const link = new ethers.Contract(ADDRESSES.link, TREASURY_ABI, provider);
const ada = new ethers.Contract(ADDRESSES.ada, TREASURY_ABI, provider);
const atom = new ethers.Contract(ADDRESSES.atom, TREASURY_ABI, provider);
const sol = new ethers.Contract(ADDRESSES.sol, TREASURY_ABI, provider);
const bear = new ethers.Contract(ADDRESSES.bear, BEAR_ABI, provider);
const wbnb = new ethers.Contract(ADDRESSES.wbnb, TREASURY_ABI, provider);
const sand = new ethers.Contract(ADDRESSES.sand, TREASURY_ABI, provider);
const mbox = new ethers.Contract(ADDRESSES.mbox, TREASURY_ABI, provider);
// const kxa = new ethers.Contract(ADDRESSES.kxa, TREASURY_ABI, ethProvider);
const ibat = new ethers.Contract(ADDRESSES.ibat, TREASURY_ABI, provider);

const bearLP = async () => {
  const bearLPWBNB = ethers.utils.formatEther(
    await wbnb.balanceOf(ADDRESSES.bearLP)
  );
  //console.log(bearLPWBNB);
  return parseFloat(bearLPWBNB).toFixed(2);
};
const bearLPTokenAmount = async () => {
  const bearLPToken = (await bear.balanceOf(ADDRESSES.bearLP)) / 1000000000;
  return parseFloat(bearLPToken).toFixed(2);
};

const bearHeldByInvestors = async () => {
  const bearTotalSupply = (await bear.totalSupply()) / 1000000000;
  const bearLPToken = (await bear.balanceOf(ADDRESSES.bearLP)) / 1000000000;
  const unicryptBear =
    (await bear.balanceOf(ADDRESSES.bearUnicrypt)) / 1000000000;
  const stakingBear =
    (await bear.balanceOf(ADDRESSES.bearStaking)) / 1000000000;
  const deadBear = (await bear.balanceOf(ADDRESSES.deadBear)) / 1000000000;
  const deployerBear =
    (await bear.balanceOf(ADDRESSES.deployerBear)) / 1000000000;
  //console.log(deployerBear)
  const devBear = (await bear.balanceOf(ADDRESSES.devBear)) / 1000000000;
  const marketingBear =
    (await bear.balanceOf(ADDRESSES.marketingBear)) / 1000000000;
  return (
    bearTotalSupply -
    bearLPToken -
    unicryptBear -
    stakingBear -
    deadBear -
    deployerBear -
    devBear -
    marketingBear
  );
};

const treasuryBNB = async () => {
  const balance = ethers.utils.formatEther(
    await provider.getBalance(ADDRESSES.treasury)
  );
  return parseFloat(balance).toFixed(2);
};

const treasuryMatic = async () => {
  const maticBalance = ethers.utils.formatEther(
    await matic.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(maticBalance).toFixed(2);
};

const treasuryAvax = async () => {
  const avaxBalance = ethers.utils.formatEther(
    await avax.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(avaxBalance).toFixed(2);
};

const treasuryFtm = async () => {
  const ftmBalance = ethers.utils.formatEther(
    await ftm.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(ftmBalance).toFixed(2);
};

const treasuryBusd = async () => {
  const busdBalance = ethers.utils.formatEther(
    await busd.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(busdBalance).toFixed(2);
};

const treasuryLink = async () => {
  const linkBalance = ethers.utils.formatEther(
    await link.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(linkBalance).toFixed(2);
};

const treasuryAda = async () => {
  const adaBalance = ethers.utils.formatEther(
    await ada.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(adaBalance).toFixed(2);
};

const treasuryAtom = async () => {
  const atomBalance = ethers.utils.formatEther(
    await atom.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(atomBalance).toFixed(2);
};

const treasurySol = async () => {
  const solBalance = ethers.utils.formatEther(
    await sol.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(solBalance).toFixed(2);
};

const treasurySand = async () => {
  const sandBalance = ethers.utils.formatEther(
    await sand.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(sandBalance).toFixed(2);
};

const treasuryMbox = async () => {
  const mboxBalance = ethers.utils.formatEther(
    await mbox.balanceOf(ADDRESSES.treasury)
  );
  return parseFloat(mboxBalance).toFixed(2);
};

// const treasuryKxa = async () => {
//     const kxaBalance = ethers.utils.formatEther(
//         await kxa.balanceOf(ADDRESSES.kxaTreasury)
//     );

//     return parseFloat(kxaBalance).toFixed(2);
// };

const treasuryIbat = async () => {
  const ibatBalance =
    ethers.utils.formatEther(await ibat.balanceOf(ADDRESSES.treasury)) *
    1000000000;
  //console.log(ibatBalance);
  return parseFloat(ibatBalance).toFixed(2);
};

const Services = ({ ...rates }) => {
  const { ada, atom, avax, bnb, ftm, ibat, kxa, link, matic, mbox, sand, sol } =
    rates;
  const [newBearLp, setNewBearLp] = useState(0);
  const [newBearLPTokenAmount, setNewBearLPTokenAmount] = useState(0);
  const [newBnbBalance, setNewBnbBalance] = useState(0);
  const [newMaticBalance, setNewMaticBalance] = useState(0);
  const [newAvaxBalance, setNewAvaxBalance] = useState(0);
  const [newFtmBalance, setNewFtmBalance] = useState(0);
  const [newBusdBalance, setNewBusdBalance] = useState(0);
  const [newLinkBalance, setNewLinkBalance] = useState(0);
  const [newAdaBalance, setNewAdaBalance] = useState(0);
  const [newAtomBalance, setNewAtomBalance] = useState(0);
  const [newSolBalance, setNewSolBalance] = useState(0);
  const [newSandBalance, setNewSandBalance] = useState(0);
  const [newMboxBalance, setNewMboxBalance] = useState(0);
  const [newKxaBalance, setNewKxaBalance] = useState(0);
  const [newIbatBalance, setNewIbatBalance] = useState(0);
  const [newBearHeldByInvestors, setNewBearHeldByInvestors] = useState(0);

  const fetchData = async () => {
    bearLP().then((value) => setNewBearLp(value));
    bearLPTokenAmount().then((value) => setNewBearLPTokenAmount(value));
    treasuryBNB().then((value) => setNewBnbBalance(value));
    treasuryMatic().then((value) => setNewMaticBalance(value));
    treasuryAvax().then((value) => setNewAvaxBalance(value));
    treasuryFtm().then((value) => setNewFtmBalance(value));
    treasuryBusd().then((value) => setNewBusdBalance(value));
    treasuryLink().then((value) => setNewLinkBalance(value));
    treasuryAda().then((value) => setNewAdaBalance(value));
    treasuryAtom().then((value) => setNewAtomBalance(value));
    treasurySol().then((value) => setNewSolBalance(value));
    treasurySand().then((value) => setNewSandBalance(value));
    treasuryMbox().then((value) => setNewMboxBalance(value));
    // treasuryKxa().then((value) => setNewKxaBalance(value));
    treasuryIbat().then((value) => setNewIbatBalance(value));
    bearHeldByInvestors().then((value) => setNewBearHeldByInvestors(value));
  };

  const total =
    bnb * newBnbBalance +
    matic * newMaticBalance +
    avax * newAvaxBalance +
    ftm * newFtmBalance +
    link * newLinkBalance +
    ada * newAdaBalance +
    atom * newAtomBalance +
    sol * newSolBalance +
    parseFloat(newBusdBalance) +
    bnb * newBearLp +
    sand * newSandBalance +
    mbox * newMboxBalance +
    // kxa * newKxaBalance +
    ibat * newIbatBalance;

  useEffect(() => {
    fetchData();
  }, []);

  const showInfo = () => {
    toast(
      "If the project hypothetically ended at this time, each investor holding 1 Million BEAR will receive this much BUSD in their wallet",
      { autoClose: 4000 }
    );
  };

  const showInfo1 = () => {
    toast(
      "Total BEAR out of 100 million minted that is held by investors at this time",
      { autoClose: 4000 }
    );
  };

  const showInfo2 = () => {
    toast(
      "This is the current value of 1 Million BEAR on PancakeSwap, after taxes",
      { autoClose: 4000 }
    );
  };

  const style = {
    color: "#F6F6F6",
    textShadow: "0px -10px 0px black",
  };

  return (
    <div className="flex justify-center w-full text-white font-Roboto">
      <div className="flex flex-col items-start justify-between px-4 pt-10 mf:flex-row">
        <div className="flex flex-col items-start justify-center flex-1 mf:mr-10">
          <div
            style={{ backgroundImage: `url(${wood})` }}
            className="flex flex-col items-center p-8 mx-auto shadow-lg font-diplomatic shadow-black rounded-2xl"
          >
            {
              <h1 style={style} className="w-full py-3 text-5xl text-center ">
                The Bear Cave
              </h1>
            }
            <h1 className="w-full text-4xl font-medium text-center">
              Total: ${total.toFixed(2)}
            </h1>
          </div>
          {/* top table */}
          <TopTable
            newMaticBalance={newMaticBalance}
            maticExchangeRate={matic}
            newAvaxBalance={newAvaxBalance}
            avaxExchangeRate={avax}
            ftmExchangeRate={ftm}
            newFtmBalance={newFtmBalance}
            newBusdBalance={newBusdBalance}
            newLinkBalance={newLinkBalance}
            linkExchangeRate={link}
            solExchangeRate={sol}
            newSolBalance={newSolBalance}
            atomExchangeRate={atom}
            adaExchangeRate={ada}
            newAtomBalance={newAtomBalance}
            newAdaBalance={newAdaBalance}
          />

          {/* gamming unity */}
          <GamingUnity
            newSandBalance={newSandBalance}
            sandExchangeRate={sand}
            newMboxBalance={newMboxBalance}
            mboxExchangeRate={mbox}
            newKxaBalance={newKxaBalance}
            newIbatBalance={newIbatBalance}
            // kxaExchangeRate={kxa}
            ibatExchangeRate={ibat}
          />

          <h1 className="w-full mt-5 text-4xl text-center text-white">
            Next Investment
          </h1>
          <div className="grid items-center w-full grid-cols-4 p-5 mt-10 text-sm text-center text-white border-2 divide-x rounded-lg lg:grid-cols-5 md:text-xl backdrop-blur-xl">
            <div>
              <img src={bnbLogo} alt="logo" className="w-12 border-2 lg:w-16" />
            </div>
            <div>Binance Smartchain</div>
            <div>{newBnbBalance}</div>
            <div className="hidden lg:block">BNB</div>
            <div> ${(bnb * newBnbBalance).toFixed(2)} </div>
          </div>
          <ArbitrageCalculation
            bnbExchangeRate={bnb}
            newBearLp={newBearLp}
            newBearLPTokenAmount={newBearLPTokenAmount}
            showInfo2={showInfo2}
            newBearHeldByInvestors={newBearHeldByInvestors}
            showInfo1={showInfo1}
            setTotal={total}
            showInfo={showInfo}
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
