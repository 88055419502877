import { ethers } from "ethers";

const GETBLOCK_API = process.env.REACT_APP_GETBLOCK_API;

const ETH_PROVIDER = process.env.REACT_APP_ETH_PROVIDER;

export const provider = new ethers.providers.JsonRpcProvider(
  `https://go.getblock.io/${GETBLOCK_API}`
);

export const ethProvider = new ethers.providers.JsonRpcProvider(
  `https://mainnet.infura.io/v3/${ETH_PROVIDER}`
);