import axios from "axios";
import React, { useEffect, useState } from "react";
import Services from "./Services";

const fetchBlockPriceTokenData = async (tokenName) => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/token/${tokenName}/`;
  const response = await axios.get(url);
  return response?.data?.currentPrice;
};

const fetchBlockPriceAvalanche = async () => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/Avalanche/`;
  const response = await axios.get(url);
  return response?.data?.currentAvalanche;
};
const fetchBlockPriceBinance = async () => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/Binance/`;
  const response = await axios.get(url);
  return response?.data?.currentBinance;
};
const fetchBlockPriceFantom = async () => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/Fantom/`;
  const response = await axios.get(url);
  return response?.data?.currentFantom;
};
const fetchBlockPricePolygon = async () => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/Polygon/`;
  const response = await axios.get(url);
  return response?.data?.currentPolygon;
};

const fetchBlockPriceSolana = async (name) => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/${name}/`;
  const response = await axios.get(url);
  return response?.data?.currentSolana;
};

const fetchBlockPriceCosmos = async (name) => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/${name}/`;
  const response = await axios.get(url);
  return response?.data?.currentCosmos;
};

const fetchBlockPriceChainlink = async (name) => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/${name}/`;
  const response = await axios.get(url);

  return response?.data?.currentChainLink;
};

const fetchBlockPriceCardano = async (name) => {
  const url = `https://blockprice.rest/api/${process.env.REACT_APP_BLOCK_PRICE_API}/${name}/`;
  const response = await axios.get(url);
  return response?.data?.currentCardano;
};

// const fetchCoinStats = async () => {
//   try {
//     const config = {
//       headers: {
//         Authorization: "Bearer w/A92/0AGGA4cQqFp7JoLYjsEifRSCv8nSzF64cp+aw=",
//       },
//     };

//     return axios
//       .get("https://api.coinstats.app/public/v1/coins", config)
//       .then((response) => response.data)
//       .catch((error) => {
//         console.error(error);
//         throw error; // Re-throw the error for caller to handle if necessary
//       });
//   } catch (error) {
//     console.log("New ", error);
//   }
// };

const CurrencyConverter = () => {
  const [rates, setRates] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sand = await fetchBlockPriceTokenData("sand");
        // const kxa = await fetchBlockPriceTokenData("kryxavia");
        const mbox = await fetchBlockPriceTokenData("mobox");
        const ibat = await fetchBlockPriceTokenData("Ibat");

        const bnb = await fetchBlockPriceBinance();
        const avax = await fetchBlockPriceAvalanche();
        const matic = await fetchBlockPricePolygon();
        const ftm = await fetchBlockPriceFantom();

        // const coins = await fetchCoinStats();
        // console.log(coins);

        const link = await fetchBlockPriceChainlink("chainlink");
        console.log("link", link);
        const ada = await fetchBlockPriceCardano("cardano");
        const atom = await fetchBlockPriceCosmos("cosmos");
        const sol = await fetchBlockPriceSolana("solana");

        setRates({
          sand,
          mbox,
          ibat,
          bnb,
          avax,
          matic,
          ftm,
          link,
          ada,
          atom,
          sol,
        });

        setLoading(false);
      } catch (err) {
        // console.log(err);
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen">
        <div
          className="inline-block w-10 h-10 bg-black rounded-full opacity-0 spinner-grow"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return <Services {...rates} />;
};

export default CurrencyConverter;
