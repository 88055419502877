import "./App.css";
import CurrencyConverter from "./Components/CurrencyConverter";


const homeStyle = {
  backgroundImage: `url("https://i.ibb.co/ZMX0Nkr/Bear-Cave-Background-Website.png")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  opacity: "100%",
};

function App() {
  return (
    <div style={homeStyle} className="min-h-screen">
      <CurrencyConverter />
    </div>
  );
}

export default App;
