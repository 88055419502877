import React from 'react'
import { bnbLogo, logo } from '../Assets'
import { FcInfo } from 'react-icons/fc'

function ArbitrageCalculation({ bnbExchangeRate, newBearLp, newBearLPTokenAmount, showInfo2, newBearHeldByInvestors, showInfo1, setTotal, showInfo }) {
    return (
        <><h1 className="w-full mt-5 text-4xl text-center text-white">
            Arbitrage Calculation
        </h1>
            <div className="grid items-center w-full grid-cols-4 gap-4 p-5 mt-10 text-sm text-center text-white border-2 divide-x rounded-lg lg:grid-cols-5 md:text-xl backdrop-blur-xl">
                <div>
                    <img src={bnbLogo} alt="logo" className="w-12 border-2 lg:w-16" />
                </div>
                <div>Bear LP</div>
                <div>{newBearLp}</div>
                <div className="hidden lg:block">BNB</div>
                <div> ${(bnbExchangeRate * newBearLp).toFixed(2)} </div>

                <div>
                    <img src={logo} alt="logo" className="w-12 border-2 lg:w-16" />
                </div>
                <div>The Bear Token</div>
                <div>1 Million</div>
                <div className="hidden lg:block">BEAR</div>
                <div className="flex items-center justify-center gap-2">
                    {" "}
                    $
                    {(
                        bnbExchangeRate *
                        (newBearLp / newBearLPTokenAmount) *
                        1000000 *
                        0.9
                    ).toFixed(2)}{" "}
                    <FcInfo onClick={showInfo2} />{" "}
                </div>

                <div>
                    <img src={logo} alt="logo" className="w-12 border-2 lg:w-16" />
                </div>
                <div>Treasury Value</div>
                <div className="flex items-center justify-center gap-2">
                    {newBearHeldByInvestors.toFixed(2).slice(0, 2)} Million{" "}
                    <FcInfo onClick={showInfo1} />{" "}
                </div>
                <div className="hidden lg:block">BEAR</div>
                <div className="flex items-center justify-center gap-2">
                    {" "}
                    ${((setTotal / newBearHeldByInvestors) * 1000000).toFixed(
                        2
                    )}{" "}
                    <FcInfo onClick={showInfo} />{" "}
                </div>
            </div></>
    )
}

export default ArbitrageCalculation