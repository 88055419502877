import React from 'react'
import { ibatLogo, kxaLogo, mboxLogo, sandLogo } from '../Assets'

function GamingUnity({ newSandBalance, sandExchangeRate, newMboxBalance, mboxExchangeRate, newKxaBalance, newIbatBalance, ibatExchangeRate }) {
    return (
        <>
            <h1 className="w-full mt-5 text-4xl text-center text-white">
                Gaming/Utility
            </h1>
            <div className="grid items-center w-full grid-cols-4 gap-4 p-5 mt-10 text-sm text-center text-white border-2 divide-x rounded-lg lg:grid-cols-5 md:text-xl backdrop-blur-xl">
                <div>
                    {" "}
                    <img
                        src={sandLogo}
                        alt="logo"
                        className="w-12 border-2 lg:w-16"
                    />{" "}
                </div>
                <div>Sand Box</div>
                <div>{newSandBalance}</div>
                <div className="hidden lg:block">SAND</div>
                <div> ${(newSandBalance * sandExchangeRate).toFixed(2)} </div>

                <div>
                    {" "}
                    <img
                        src={mboxLogo}
                        alt="logo"
                        className="w-12 border-2 lg:w-16"
                    />{" "}
                </div>
                <div>Mobox</div>
                <div>{newMboxBalance}</div>
                <div className="hidden lg:block">MBOX</div>
                <div> ${(newMboxBalance * mboxExchangeRate).toFixed(2)} </div>

                {/* <div>
                    {" "}
                    <img
                        src={kxaLogo}
                        alt="logo"
                        className="w-12 border-2 lg:w-16"
                    />{" "}
                </div>
                <div>Kryxivia</div>
                <div>{newKxaBalance}</div>
                <div className="hidden lg:block">KXA</div>
                <div> ${(newKxaBalance * kxaExchangeRate).toFixed(2)} </div> */}

                <div>
                    {" "}
                    <img
                        src={ibatLogo}
                        alt="logo"
                        className="w-12 border-2 lg:w-16"
                    />{" "}
                </div>
                <div>BattleInfinity</div>
                <div>{newIbatBalance}</div>
                <div className="hidden lg:block">IBAT</div>
                <div> ${(newIbatBalance * ibatExchangeRate).toFixed(2)} </div>
            </div>
        </>
    )
}

export default GamingUnity