
// all abi

export const TREASURY_ABI = [
    "function name() external view returns(string memory)",
    "function symbol() external view returns (string memory)",
    "function balanceOf(address account) external view returns (uint256)",
];
export const BEAR_ABI = [
    "function name() external view returns(string memory)",
    "function symbol() external view returns (string memory)",
    "function balanceOf(address account) external view returns (uint256)",
    "function totalSupply() external view returns (uint256)",
];