import React from "react";
import {
  adaLogo,
  atomLogo,
  avaxLogo,
  busdLogo,
  ftmLogo,
  linkLogo,
  maticLogo,
  solLogo,
} from "../Assets";

function TopTable({
  newMaticBalance,
  maticExchangeRate,
  newAvaxBalance,
  avaxExchangeRate,
  ftmExchangeRate,
  newFtmBalance,
  newBusdBalance,
  newLinkBalance,
  linkExchangeRate,
  solExchangeRate,
  newSolBalance,
  atomExchangeRate,
  adaExchangeRate,
  newAtomBalance,
  newAdaBalance,
}) {
  return (
    <>
      <div className="grid items-center w-full grid-cols-4 gap-4 p-5 mt-10 text-sm text-center text-white border-2 divide-x lg:grid-cols-5 rounded-xl md:text-xl backdrop-blur-xl">
        <div>
          <img src={maticLogo} alt="logo" className="w-12 border-2 lg:w-16" />
        </div>
        <div>Polygon</div>
        <div>{newMaticBalance}</div>
        <div className="hidden lg:block">MATIC</div>
        <div> ${(newMaticBalance * maticExchangeRate).toFixed(2)} </div>

        <div>
          <img src={avaxLogo} alt="logo" className="w-12 border-2 lg:w-16" />
        </div>
        <div>Avalanche</div>
        <div>{newAvaxBalance}</div>
        <div className="hidden lg:block">AVAX</div>
        <div>${(newAvaxBalance * avaxExchangeRate).toFixed(2)}</div>

        <div>
          <img src={ftmLogo} alt="logo" className="w-12 border-2 lg:w-16" />
        </div>
        <div>Fantom</div>
        <div>{newFtmBalance}</div>
        <div className="hidden lg:block">FTM</div>
        <div>${(newFtmBalance * ftmExchangeRate).toFixed(2)}</div>

        <div>
          <img src={busdLogo} alt="logo" className="w-12 border-2 lg:w-16" />
        </div>
        <div>Binance-US Dollar</div>
        <div>{newBusdBalance}</div>
        <div className="hidden lg:block">BUSD</div>
        <div>${newBusdBalance}</div>

        <div>
          <img src={linkLogo} alt="logo" className="w-12 border-2 lg:w-16" />
        </div>
        <div>Chainlink</div>
        <div>{newLinkBalance}</div>
        <div className="hidden lg:block">LINK</div>
        <div>${(newLinkBalance * linkExchangeRate).toFixed(2)}</div>

        <div>
          <img src={adaLogo} alt="logo" className="w-12 border-2 lg:w-16" />
        </div>
        <div>Cardano</div>
        <div>{newAdaBalance}</div>
        <div className="hidden lg:block">ADA</div>
        <div>${(newAdaBalance * adaExchangeRate).toFixed(2)}</div>

        <div>
          {" "}
          <img
            src={atomLogo}
            alt="logo"
            className="w-12 border-2 lg:w-16"
          />{" "}
        </div>
        <div>Cosmos</div>
        <div>{newAtomBalance}</div>
        <div className="hidden lg:block">ATOM</div>
        <div> ${(newAtomBalance * atomExchangeRate).toFixed(2)} </div>

        <div>
          {" "}
          <img
            src={solLogo}
            alt="logo"
            className="w-12 border-2 lg:w-16"
          />{" "}
        </div>
        <div>Solana</div>
        <div>{newSolBalance}</div>
        <div className="hidden lg:block">SOL</div>
        <div> ${(newSolBalance * solExchangeRate).toFixed(2)} </div>
      </div>
    </>
  );
}

export default TopTable;
